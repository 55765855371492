import classNames from "classnames";
import styles from "./styles/Applications.module.scss";
import openbtn from "../images/icon/openbtn.svg";
import { useAuth } from "@mgi-labs/mgi-id";
import { AppsInfos } from "./Domain/AvailableApps";
import { useTranslation } from "react-i18next";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const RessourcesApp = ({ app }: { app: AppsInfos }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classNames(
          styles.padding,
          styles.httools,
          styles.float_left,
          styles.httoolssm,
          "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3"
        )}
      >
        <a
          href={app.link}
          target={!auth.authenticated ? "self" : "_blank"}
          rel="noreferrer"
          className={classNames(
            styles.tile,
            styles.textcolor,
            styles.toolscolor
          )}
        >
          <div className={classNames(styles.light)}></div>

          {auth.authenticated && (
            <div
              className={classNames(styles.topbar, styles.toolbar, "col-12")}
            >
              <p>{app.name}</p>
              <div className={classNames(styles.open)}>
                <p>{auth.authenticated ? t("Ouvrir") : t("Decouvrir")}</p>
                <img src={openbtn} alt="openbtn" />
              </div>
            </div>
          )}
          <div className={classNames(styles.content, "col-12")}>
            <div className={classNames(styles.dispflex, styles.toolname)}>
              <img
                className={classNames(
                  styles.bglogo,
                  app.name === "Documentation" ? styles.bglogobig : "",
                  isharrisbruno()
                    ? app.name === "guidelines"
                      ? styles.bglogohb
                      : ""
                    : ""
                )}
                src={app.logo}
                alt={app.name}
              />
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default RessourcesApp;
