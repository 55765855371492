import classNames from "classnames";
import styles from "./styles/workteam.module.scss";
import { UserRepresentation } from "../types";
import { useTranslation } from "react-i18next";
import { useUser } from "@mgi-labs/mgi-id";
import { useNavigate } from "react-router-dom";

export type AppAuthorizationsType = {
  apps: string[];
  id: string;
};

const WorkteamUser = ({
  user,
  handleDeleteUser,
  setSelectedUser,
  selectedUser,
  isMemberPage,
  accessUserCount,
  mutateGroup,
}: {
  user: UserRepresentation;
  handleDeleteUser: (id: string[]) => void;
  setSelectedUser?: React.Dispatch<string>;
  selectedUser?: string;
  isMemberPage?: boolean;
  accessUserCount?: number | undefined;
  mutateGroup?: () => void;
}) => {
  const firstName = user?.firstName ?? "";
  const lastName = user?.lastName ?? "";
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
  const { t } = useTranslation();

  const currentUser = useUser();

  /* TODO: Functionnalize uniq role access */

  // const backend = useBackend();

  // const [hasAccess, setHasAccess] = useState<boolean>(
  //   user.realmRoles?.includes("hasAccess")
  // );

  // const handleChangeUserRoles = useCallback(async () => {
  //   if (
  //     hasAccess ||
  //     (accessUserCount && accessUserCount < backend.limitation.user)
  //   ) {
  //     setHasAccess(!hasAccess);
  //     await backend.connexion.changeUserAccess(
  //       user.id,
  //       isharrisbruno() ? "HB" : "MGI",
  //       !hasAccess
  //     );
  //   } else {
  //     toast.error("limit reached");
  //   }
  //   mutateGroup && mutateGroup();
  // }, [
  //   accessUserCount,
  //   backend.connexion,
  //   backend.limitation.user,
  //   hasAccess,
  //   mutateGroup,
  //   user.id,
  // ]);

  /* END TODO */

  const navigate = useNavigate();

  return (
    <>
      <div
        className={classNames(
          styles.Allproject_project,
          isMemberPage ? styles.hovermember : "",
          " row",
          user.id === selectedUser && styles.selected
        )}
        style={{ cursor: isMemberPage ? "pointer" : "default" }}
        key={user.firstName}
        onClick={() => setSelectedUser && setSelectedUser(user.id)}
      >
        <div className={classNames(styles.Allproject_project_user)}>
          {/*  ajouter la class styles.compteregister après onoff quand un utilisateur n'a pas accès au pack */}

          <div className={classNames(styles.compte)}>
            <p>{initials}</p>
          </div>
        </div>
        <div className={classNames(styles.Allproject_project_text)}>
          <p className={classNames(styles.width100)}>
            {user.firstName} {user.lastName}
          </p>
          <p className={classNames(styles.width100, styles.email)}>
            {user.email}
          </p>
        </div>

        {user.id !== currentUser?.id ? (
          <div className={classNames(styles.morebtn)}>
            <div
              className={classNames(styles.btnonecontent)}
              onClick={() => handleDeleteUser([user.id])}
              title={t("deleteuser")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
              >
                <path
                  id="cancel"
                  d="M16.5,33.6,24,26.1l7.5,7.5,2.1-2.1L26.1,24l7.5-7.5-2.1-2.1L24,21.9l-7.5-7.5-2.1,2.1L21.9,24l-7.5,7.5ZM24,44a19.352,19.352,0,0,1-7.75-1.575A20.15,20.15,0,0,1,5.575,31.75a19.978,19.978,0,0,1,0-15.55,19.988,19.988,0,0,1,4.3-6.35A20.5,20.5,0,0,1,16.25,5.575a19.978,19.978,0,0,1,15.55,0A19.969,19.969,0,0,1,42.425,16.2a19.978,19.978,0,0,1,0,15.55,20.5,20.5,0,0,1-4.275,6.375,19.988,19.988,0,0,1-6.35,4.3A19.475,19.475,0,0,1,24,44Zm0-3a16.359,16.359,0,0,0,12.05-4.975A16.424,16.424,0,0,0,41,24a16.4,16.4,0,0,0-4.95-12.05A16.4,16.4,0,0,0,24,7a16.424,16.424,0,0,0-12.025,4.95A16.359,16.359,0,0,0,7,24a16.383,16.383,0,0,0,4.975,12.025A16.382,16.382,0,0,0,24,41ZM24,24Z"
                  transform="translate(-4 -4)"
                />
              </svg>
            </div>
            {/* )} */}
          </div>
        ) : (
          <div className={classNames(styles.morebtn)}>
            <div
              className={classNames(styles.btnonecontent)}
              onClick={() => navigate("/profil")}
              title={t("changeuser")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  className={classNames(styles.fill)}
                  d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
                />
              </svg>
            </div>

            {/* )} */}
          </div>
        )}

        <div className={classNames(styles.onoffzone)}>
          {/* <div className={classNames(styles.userbtn)}>
            <p>{t("userpack")}</p>
          </div> */}
          <div
            className={classNames(styles.onoffcontainer)}
            // onClick={handleChangeUserRoles} | KEEP 4 LATER
          >
            {/*  ajiuter la class styles.btnwhite après onoff quand un utilisateur n'a pas accès au pack 
                        <div
              className={classNames(
                styles.onoff
                // !hasAccess && styles.btnwhite  | KEEP 4 LATER
              )}
            >
              <div className={classNames(styles.btninterieur)}></div>
            </div>
            
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkteamUser;
