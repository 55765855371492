import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseGroup } from "../hooks/UseGroup";
import { UserRepresentation } from "../types";
import alertimg from "../images/icon/alertimg.svg";
import mgiconnect from "../images/mgiconnect.png";
import styles from "./styles/workteam.module.scss";
import WorkteamUser from "./WorkteamUser";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import Chooseequipment from "./Chooseequipment";
import Addequipmentdesktop from "./Addequipmentdesktop";
import { useAllMachines } from "../hooks/useAllMachines";
// import cataloguesproj from "../images/icon/cataloguesproj.svg";
// import cloud from "../images/icon/cloud.svg";

import { getUserApps } from "./Domain/AvailableApps";
import { useUser } from "@mgi-labs/mgi-id";
import Adduser from "./adduser";
import { UseApproveStats } from "../hooks/UseApproveStats";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { OrderRenewDto } from "./Domain/prestashop";

const Workteam = () => {
  const backend = useBackend();
  const { t, i18n } = useTranslation();
  const { data: groupUsers, error, mutate: mutateGroup } = UseGroup();

  const { data: machines, mutate } = useAllMachines();
  const { data: stats } = UseApproveStats();

  const project = stats?.projects ? stats.projects : 0;
  const totalProjectStatuspercent =
    (project / backend.limitation.projects) * 100;

  const totalBooks = stats?.catalog ? stats.catalog : 0;
  const totalBooksStatuspercent = (totalBooks / backend.limitation.book) * 100;

  const user = useUser();

  const userApps = getUserApps(user?.availableApps ?? []);
  const hasDashboardAccess = userApps.includes("dashboard");

  // Needed later states
  const [isVisibleAddUser, setIsVisibleAddUser] = useState(false);
  const handleToggleAddUser = useCallback(() => {
    setIsVisibleAddUser((isVisibleAddUser) => !isVisibleAddUser);
  }, []);

  async function handleDeleteUser(id: string[]) {
    if (window.confirm(t("confirm_delete"))) {
      try {
        await backend.connexion.deleteUser(id);
      } finally {
        mutateGroup();
      }
    }
  }
  const [, setIsLoading] = useState<boolean>(false);

  const handleRenew = useCallback(
    async (e: { preventDefault: () => void }) => {
      try {
        e.preventDefault();
        setIsLoading(true);
        const order: OrderRenewDto = {
          type: backend.limitation.packType ? backend.limitation.packType : "",
          endDate: backend.limitation.endDate,
          userId: backend.limitation.prestaId,
          currency: i18n.language.toUpperCase().includes("FR") ? "€" : "$",
        };
        const pdf = await backend.connexion.createRenewOrder(order);
        const href = URL.createObjectURL(pdf);
        const link = document.createElement("a");
        link.href = href;

        link.download = `LUR_RENEW_${format(new Date(), "yyyy-MM-dd")}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } finally {
        setIsLoading(false);
      }
    },
    [
      backend.connexion,
      backend.limitation.endDate,
      backend.limitation.packType,
      backend.limitation.prestaId,
      i18n.language,
    ]
  );

  const [selectedMachine, setSelectedMachine] = useState<number | undefined>();

  useEffect(() => {
    if (!selectedMachine && machines?.length === 1) {
      setSelectedMachine(machines[0].id);
    }
  }, [machines, selectedMachine]);

  const location = useLocation();

  const [isVisibleEquipment, setIsVisibleEquipment] = useState<boolean>(
    location.hash.includes("addequipment")
  );

  const accessUserCount = useMemo(() => {
    return groupUsers?.filter((user) => user.realmRoles?.includes("hasAccess"))
      .length;
  }, [groupUsers]);

  return (
    <>
      {/* Those components will be usefull later */}
      {isVisibleAddUser && (
        <Adduser
          isVisibleAdduser={isVisibleAddUser}
          handleToggleVisibleAdduser={handleToggleAddUser}
          setVisibleAddUser={setIsVisibleAddUser}
        />
      )}

      {isVisibleEquipment && (
        <Addequipmentdesktop
          setIsVisibleEquipment={setIsVisibleEquipment}
          mutate={mutate}
        />
      )}

      <div className={classNames(styles.Container)}>
        {/* <div className={classNames(styles.title)}></div> */}

        {hasDashboardAccess && (
          <Chooseequipment
            machines={machines}
            mutate={mutate}
            setIsVisibleEquipment={setIsVisibleEquipment}
          ></Chooseequipment>
        )}

        <div
          className={classNames(
            styles.abonnementactif,
            hasDashboardAccess
              ? "col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
              : "col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9",
            styles.ht2
          )}
        >
          <div className={classNames(styles.Allproject_projects_section_sort)}>
            <div className={classNames(styles.Allproject_projects_sort)}>
              {t("Abonnementactif")}
            </div>
            <p>
              {t("jusquau")}{" "}
              {new Date(backend.limitation.endDate).toLocaleDateString(
                i18n.language,
                {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }
              )}
            </p>
          </div>
          <div className={classNames(styles.packimgtext)}>
            <div className={classNames(styles.packimg)}>
              <img src={mgiconnect} alt="mgiconnect" />
            </div>

            <div className={classNames(styles.packtextzone)}>
              <div className={classNames(styles.packtitle)}>
                <p>{t(`Pack.${backend.limitation.packType}`)}</p>
              </div>
              <div className={classNames(styles.packtext)}>
                <div className={classNames(styles.onepacktext)}>
                  <p className={classNames(styles.opacity)}>{t("nbuser")} :</p>
                  <p>
                    {backend.limitation.user} {t("user")}
                  </p>
                </div>
                <div className={classNames(styles.onepacktext)}>
                  <p className={classNames(styles.opacity)}>
                    {t("nbprojects")} :
                  </p>
                  <p>
                    {backend.limitation.projects} {t("projects")}
                  </p>
                </div>
                <div className={classNames(styles.onepacktext)}>
                  <p className={classNames(styles.opacity)}>{t("nbbook")} :</p>
                  <p>
                    {backend.limitation.book} {t("book")}
                  </p>
                </div>
                {/* <button onClick={() => handleRenew()}>Renew</button> */}
                {/* <div className={classNames(styles.buttonpack)}>
                      <div></div>
                      <NavLink
                        to="/extensions"
                        className={classNames(styles.onebutton)}
                      >
                        <p>{t("Etendre")}</p>
                      </NavLink>
                    </div> */}
              </div>
              <a
                onClick={(e) => handleRenew(e)}
                className={classNames(styles.btnrenouvelerabo)}
                href={"/"}
              >
                {t("renouvelerabo")}
              </a>
            </div>
          </div>
        </div>

        {error ? (
          <div
            className={classNames(
              styles.faildata,
              "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            )}
          >
            <div>
              <img src={alertimg} alt="Alert" />
              <p>Failed to load data</p>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              styles.tablecontainer,
              styles.ht3,
              "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
            )}
          >
            <div
              className={classNames(
                styles.Tbl_Gestion_User,

                "col-12"
              )}
            >
              <div
                className={classNames(
                  styles.Allproject_projects_section_sort,
                  "row"
                )}
              >
                <div className={classNames(styles.Allproject_padding)}>
                  <div
                    className={classNames(
                      styles.Allproject_projects_sort,
                      styles.titlewidth
                    )}
                  >
                    {t("Membres")}
                  </div>
                  <p>
                    {groupUsers?.length} / {backend.limitation.user}
                  </p>
                </div>
              </div>
              <div
                id="scrollableTBody"
                className={classNames(
                  styles.Allproject_projects_content,
                  groupUsers &&
                    !(backend.limitation.user > groupUsers?.length) &&
                    styles.Allproject_projects_content_no_button,
                  "row"
                )}
              >
                {Array.isArray(groupUsers)
                  ? groupUsers.map((user: UserRepresentation, i: number) => (
                      <WorkteamUser
                        user={user}
                        key={i}
                        handleDeleteUser={handleDeleteUser}
                        accessUserCount={accessUserCount}
                        mutateGroup={mutateGroup}
                      />
                    ))
                  : null}
              </div>
              {Array.isArray(groupUsers) &&
                true &&
                backend.limitation.user > groupUsers?.length && (
                  <div
                    className={classNames(styles.adduserbtn)}
                    onClick={() => setIsVisibleAddUser(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29.089"
                      height="29.089"
                      viewBox="0 0 29.089 29.089"
                    >
                      <path
                        id="Tracé_94"
                        data-name="Tracé 94"
                        d="M17.724,9.147H14.865v5.718H9.147v2.859h5.718v5.718h2.859V17.724h5.718V14.865H17.724ZM16.295,2A14.295,14.295,0,1,0,30.589,16.295,14.3,14.3,0,0,0,16.295,2Zm0,25.73A11.436,11.436,0,1,1,27.73,16.295,11.451,11.451,0,0,1,16.295,27.73Z"
                        transform="translate(-1.75 -1.75)"
                        fill="#fff"
                      />
                    </svg>
                    <p>{t("Add_user")}</p>
                  </div>
                )}
            </div>

            {/* Hidden add user components */}
            {/* {Array.isArray(groupUsers) &&
                groupUsers.length < backend.limitation.user && (
                  <div className={classNames(styles.adduser)}>
                    <button onClick={handleToggleAddUser}>
                      <p>{t("Add_user")}</p>
                    </button>
                  </div>
                )} */}

            {/*  <div className={classNames(styles.addMultipleUser)}>
               <button onClick={handleToggleAddMultipleUser}>
                  <p>{t("Add_multiple_user")}</p>
                </button> 
              </div>*/}
          </div>
        )}

        <div
          className={classNames(
            styles.padding,
            styles.float_left,
            styles.ht1,
            styles.leftgraph,
            "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile)}>
            <div className={classNames(styles.light)}></div>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.logotext)}>{t("status")}</p>
              {/* Si max change a nouveau d'avis pour les icones
                       <div className={classNames(styles.text_icon)}>
                        <img
                          className={classNames(styles.cloud, styles.DojaCat)}
                          src={cloud}
                          alt="Cloud"
                        />
                      </div> */}
            </div>
            <div className={classNames(styles.app_metrics)}>
              <div className={classNames(styles.onecontentzoneoee, "row")}>
                <div className={classNames(styles.dashtrs, "row")}>
                  <div
                    className={classNames(
                      styles.numberzone,
                      styles.doublegraph
                    )}
                  >
                    <div className={classNames(styles.linedashzone)}>
                      <div className={classNames(styles.titleline)}>
                        <p>{t("Utilisateurs")}</p>
                      </div>
                      <div className={classNames(styles.linedash)}>
                        <div
                          style={{ width: "20%" }}
                          className={classNames(styles.value, styles.bluevalue)}
                        ></div>
                        <div
                          style={{ width: "80%" }}
                          className={classNames(styles.background)}
                        ></div>
                      </div>
                      <p className={classNames(styles.linefont)}>
                        {groupUsers?.length} {t("utilisateurssur")}{" "}
                        {backend.limitation.user}
                      </p>
                    </div>
                    <div className={classNames(styles.linedashzone)}>
                      <div className={classNames(styles.titleline)}>
                        <p>{t("equipments")}</p>
                      </div>
                      <div className={classNames(styles.linedash)}></div>
                      <p
                        className={classNames(
                          styles.linefont,
                          styles.noopacity
                        )}
                      >
                        <span className={classNames(styles.numberline)}>
                          {machines?.length ?? 0}
                        </span>
                        <span className={classNames(styles.opactity06)}>
                          {t("connected")}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.padding,
            styles.float_left,
            styles.ht1,
            styles.rightgraph,

            "col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          )}
        >
          <div className={classNames(styles.tile)}>
            <div className={classNames(styles.light)}></div>
            <div className={classNames(styles.topbar, "col-12")}>
              <p className={classNames(styles.logotext)}>MGI Approve</p>
            </div>
            <div className={classNames(styles.app_metrics)}>
              <div className={classNames(styles.onecontentzoneoee, "row")}>
                <div className={classNames(styles.dashtrs, "row")}>
                  <div
                    className={classNames(
                      styles.numberzone,
                      styles.doublegraph
                    )}
                  >
                    <div className={classNames(styles.linedashzone)}>
                      <div className={classNames(styles.titleline)}>
                        <p>{t("number_projects")}</p>
                      </div>
                      <div className={classNames(styles.linedash)}>
                        <div
                          style={{
                            width: totalProjectStatuspercent + "%",
                          }}
                          className={classNames(styles.value, styles.bluevalue)}
                        ></div>
                        <div
                          style={{
                            width: 100 - totalProjectStatuspercent + "%",
                          }}
                          className={classNames(styles.background)}
                        ></div>
                      </div>
                      <p className={classNames(styles.linefont)}>
                        {project} {t("projects_used")}{" "}
                        {backend.limitation.projects}
                      </p>
                    </div>
                    <div className={classNames(styles.linedashzone)}>
                      <div className={classNames(styles.titleline)}>
                        <p>{t("Number")}</p>
                      </div>
                      <div className={classNames(styles.linedash)}>
                        <div
                          style={{
                            width: totalBooksStatuspercent + "%",
                          }}
                          className={classNames(styles.value, styles.bluevalue)}
                        ></div>
                        <div
                          style={{
                            width: 100 - totalBooksStatuspercent + "%",
                          }}
                          className={classNames(styles.background)}
                        ></div>
                      </div>
                      <p className={classNames(styles.linefont)}>
                        {totalBooks} {t("books_used")} {backend.limitation.book}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisibleEquipment && hasDashboardAccess && (
        <Addequipmentdesktop
          setIsVisibleEquipment={setIsVisibleEquipment}
          mutate={mutate}
        />
      )}
    </>
  );
};

export default Workteam;
