export const translate = {
  en: {
    translation: {
      CGU: "Terms & conditions",
      CGUlink:
        "https://connect.mgi-labs.com/cgu/MGI_Connect_Terms_and_Conditions_of_use.pdf",
        hbCGUlink:
        "https://connect.harris-bruno-connect.com/cgu/HB_Connect_Terms_and_Conditions_of_use.pdf",
      //Account

      Tabkm: "List of created accounts",
      Logout: "Logout",
      login_text: "Login",
      user_has_been_successfuly: "The user {{mail}} has been successfuly",
      validated: "validated",
      rejected: "rejected",
      error: "Error",
      error_validating:
        "There has been an error trying to validating this user",
      at_least_password: "Password must have at least 8 characters",
      password_must_match: "Passwords must match",
      valid_country: "Must select a valid country",
      valid_field: "Must fill this field",
      need_verification: "Your account needs to be verified",
      email_used: "Email already used",
      already_rejected_title: "User already rejected",
      already_validated_title: "User already validated",
      already_rejected: "The account of this user has already been rejected",
      already_validated:
        "The account of the user {{mail}} has already been validated",
      must_machine: "You must select at least one machine",
      mandatory: "Mandatory fields",

      Catalogue: "Virtual book",
      Predict: "ALPHAJET KM BF Maintenance",

      Predict2: "AccurioShine Maintenance",
      ALPHAJETKMBFprod: "ALPHAJET KM BF Production",
      AccurioShineprod: "AccurioShine Production",

      Nouveautés: "News",
      Utilisateurs: "Users",
      users: "users",
      utilisateurssur: "users on",
      equipments: "Equipments",
      connected: "Connected",
      status: "Status",
      Projetscata: "Projects and Catalogs",
      //Navbar
      Home: "Home",
      My_Profile: "My Profile",
      Personal_Information: "My Profile",
      Personal_Information1: "Personal",
      Personal_Information2: "Informations",
      assistanceutilisateurs: "User support",
      suport_title: "Are you having trouble ?",
      suport_commercial_title: "Commercial contact",
      Password: "Password",
      Methods_of_payment: "Methods of payment",
      User_management: "Organization",
      User_management1: "Organization",
      Members: "Members",
      User_management2: "",
      Membres: "Members",
      My_machines: "My Machines",
      Applications: "Applications",
      Security: "Security",
      Applicationstexte:
        'Go to the "Applications" page to find all the applications and tools available.',
      KMadduser: "Share account",
      //Home
      Tester: "Try",
      Hello: "Hello",
      Welcome: ", Welcome to the MGI Connect platform !",
      New: "Come and discover what's new in",
      Discover: "Discover Approve",
      Decouvrir: "Discover",
      Ouvrir: "Open",
      vistsite: "Visit website",
      Registerproduct: "Register a product",
      Shopfoilproduct: "Buy FOIL",
      Testermaintenant: "Test now",
      Abonner: "Subscribe",
      Extensions: "Add-ons",
      packhometext:
        "The MGI Connect packs give you access to three online applications to get the most out",
      packhometext2: "of your MGI equipment.",



      Click: "with a single click",
      AddUsers: "Add Users",
      AccesProfile: "My profile",
      Profile: "My Profile",
      Profile_content:
        "Change your personal information at any time by simply clicking on the Profile tab.",
      Organisation: "Organisation",
      Organisation_content:
        "Manage your organisation and invite employees to join your workspace.",
      Subscriptions: "Subscriptions",
      Starter_text: "Active: MGI Connect Package:",
      Starter_text_1: "- 1 user",
      Starter_text_2: "- 100 projects",
      Starter_text_3: "- 1 Virtual Book",
      More_informations: "More Informations",
      upgrade: "Upgrade",
      lasttime: "/ 24 hours",
      used: "Already used",
      Availibleproj: "Projects",
      Number: "Number of virtual book",
      campaigns: "Current campaigns",
      month: "/Month",
      books_used: "Virtual books used out of",
      campaigns_sent: "Campaigns sent out of",
      projects_stored: "Projects stored on",
      Storage_space: "Storage space",
      Year: "/Year",
      projects_used: "Projects used out of",
      number_projects: "Number of projects",
      Compte_assignés: "Assigned accounts:",
      compte_sur: "accounts on",

      Unique: "Unique",
      Unique_content:
        "MGI Approve is the first collaborative cloud application for Print Professionals, Brands and Agencies.",
      Strong: "Powerful",
      Strong_content:
        "MGI Approve process in few seconds your PDF files and transforms them into realistic 3-dimensional objects, which can be manipulated remotely, with a simple click",

      Accessible: "ACCESSIBLE",
      Accessible_content:
        "MGI APPROVE ne nécessite aucun investissement informatique, s’utilise de façon intuitive avec très peu de formation et réduit très fortement :",
      fabricationcosts: "Les coûts de fabrication de maquettes",
      equipmentimmo: "L’immobilisation des équipements",
      expeditioncosts: "Les coûts d’expédition",
      aprobationdelai: "Les délais d’approbation",
      Intuitif: "Simple",

      Intuitif_content:
        "MGI Approve is used through a web browser, from any computer, tablet or smartphone. Its validation process includes online discussion and annotations.",
      Sécurisé: "Secured",
      Sécurisé_content:
        "MGI Approve is a completely secure cloud service whose data is hosted on the Dassault Systèmes OutScale platform. All exchanges are encrypted from end to end, thus guaranteeing total integrity and confidentiality",
      Marketing_digital: "Digital marketing",
      Marketing_content:
        "MGI Approve allows, at a lower cost and without delay, to increase the number of models offered to one or multiple customers",

      Vernis: "Flat or embossed selective varnish",
      Dorure: "Flat or embossed hot foil stamping",
      Impression: "Four-color printing and more",
      Traits: "Cutting and folding lines",

      Données_Protégées: "Protected data",
      Données_Confidentielles: "Confidential data",
      Données_Cryptées: "Encrypted data",

      Cibler: "Target added value",
      Mesurer: "Measure its impact in real time",
      Créer: "Create marketing campaigns",

      //My Profile
      account_inf: "Account information",
      Name: "Name",
      First_name: "First Name",
      Email: "Email Address",
      Company_adresse: "Company / Address",
      Company: "Company",
      company_code: "Company Code",
      telephone: "Phone",
      telephonemobile: "Mobile phone",
      copied: "Company code copied",
      Country: "Country",
      Address: "Address",
      zipcode: "Zip Code",
      CP: "Zip Code",
      phone: "Phone",
      mobile: "Mobile Phone",
      Position: "Position Occupied",
      City: "City",
      Photo: "Drag a profile photo here",
      Choose: "Choose a File",
      Secondary: "Secondary",
      error_save_theme: "Error while saving new theme",
      createAccount: {
        success: "Your MGI Connect account has been created successfully !",
        successhb: "Your Harris & Bruno Connect account has been created successfully !",
        
        success_text:
          "In order to finalise your registration, please follow the link sent to the email address ",
        verification:
          "Your MGI account will be validated by our teams within 24 hours.",
          verificationhb:
          "Your Harris & Bruno account will be validated by our teams within 24 hours.",
        verification_text:
          "You will receive an email once your account has been validated",
        verification_text2: "to finalize your registration.",
      },
      succesfuly_updated_infos: "Profile informations succesfully updated",
      error_updating: "Error while updating informations",

      lastprojcreate: "Dernier projet créé: ",
      nbprojcreate: "Nombre de projets crées",
      lastcatcreate: "Dernier catalogue virtuel créé: ",
      nbcatcreate: "Nombre de catalogues virtuels crées",

      changeinfos: "Change your profile informations",
      saveprofile: "Save",

      KMcreateAccount: {
        success:
          "Créer des compte MGI Connect et délivrer un accès client à la version trial MGI Approve.",
        success_text:
          "Votre client finalisera son compte via l’e-mail de confirmation.",
        create_another_account: "Create another account",
      },
      need_help: "Need help ?",

      KMAccountsTable: {
        company: "Company",
        name: "Name",
        email: "Email",
        createdAt: "Creation",
        lastLogin: "Last login",
        never: "Never",
        my_clients: "My Clients",
        lastProject: "Last Project",
        lastCatalog: "Last Book",
        no_last_catalog: "No last book",
        no_last_project: "No last project",
      },

      //User Managment
      email: "Email",
      first_name: "First name",
      last_name: "Last name",
      add_user: "Add user",
      language: "Language",
      redirect_app: "Redirect to application",
      your_first_name: "You first name",
      your_last_name: "Your name",
      send_invite: "Send invite",
      status_updated: "Status has correctly been updated",
      apps_updated: "User apps has been correctly updated",
      error_save_apps: "Error while updating user apps",
      confirm_delete: "Do you really want to delete the user {{user}} ?",
      Add_user: "Add user",
      Add_multiple_user: "Add multiple users",
      importerliste: "Import a contact list",
      filecsv: "File CSV, XLS, CSV Outlook",
      Abonnementactif: "Active subscription",
      jusquau: "Until",
      nbuser: "Number of users",
      nbprojects: "Number of projects",
      nbbook: "Number of virtual books",
      user: "users",
      projects: "projects",
      book: "virtual books",
      cataloguesvirtuels: "virtual(s) book(s)",
      Etendre: "Extend",
      Pack: {
        starter: "Starter Pack",
        pro: "Pro Pack",
        mgi: "MGI Pack",
        no: "No Pack",
        beta: "Beta Pack",
        ext_ut: "1 User Extension",
        ext_cat: "1 Virtual book Extension",
        ext_pro: "500 Projects Extension",
      },
      starter: "Starter Pack",
      pro: "Pro Pack",
      mgi: "MGI Pack",
      periode: "Period",
      debut: "Beginning",
      Changement: "Change",
      End: "End",
      details: "Details",
      ajoutuser: "Add user(s)",
      ajoutprojects: "Add project(s)",
      ajoutbook: "Add virtual book(s)",
      Commander: "Download",
      Commandertext:
        "Once the License upgrade request is downloaded, please send this document to your distributor.",
      orMore: " or more",
      ajoutPro: "Pro Pack",
      HT: "Excl. Tax",

      Offrealademande: "Offer on demand",
      Offrealademandetext:
        "An offer dedicated to a specific request, such as integrating MGI Approve into your existing workflow.",
      cbdecomptebesoin: "How many user accounts do you need ?",
      cbdeprojetbesoin: "How many projects do you need ?",
      cbdecatabesoin: "How many virtual books do you need ?",
      newabo: "Licence upgrade request",
      //Password
      Current_password: "Current Password",
      New_password: "New Password",
      Password_confirmation: "Password confirmation",
      Forgot: "Change your password",
      Send_email: "Reset your password",
      Send_SMS: "Verification code by text message",
      Sent_email: "An email has been sent to : ",
      Deleted_user: "has been deleted",
      Confirm: "Confirm",
      changed_pwd: "Password has correctly been changed",
      deleteuser: "Delete user",
      changeuser: "Edit profile",
      delete: "Delete",
      //Change status

      close: "Close",
      user_status: "User status",
      standard: "Standard",
      standard_user_description:
        "A user with standard status must receive authorization from an Admin user to access the various applications and functions of their organization.",
      admin: "Administrator",
      admin_user_description:
        "A user with an Admin status can access all of their organization's applications and functions. It also has the following (management) rights: ",
      admin_user_desc1: "Removing / Adding users",
      admin_user_desc2: "Assign status",
      apply: "Apply",
      Comptes_utilisateurs: "User account",

      //support

      Support: "Assistance",
      indentifiervous: "Identify yourself",
      problemerencontre: "Problem encountered on the Application",
      decrivezprob: "Describe the problems / difficulties you identified :",
      decrivezprob_commercial: "Your message",
      Entreprise: "Company",
      lastName: "Last Name",
      Soumettre: "Send",
      support_success: "You message has been succesfuly sent",
      support_error: "There was an error sending your message",

      topproject: "Top 3 projects visited",
      visitespar: "visit(s)",

      //createaccount
      // contactsupport: "Assistance utilisateurs",
      contactsupport: "User support",

      contactus: "Contact us",

      createtext1: "Create your MGI Connect account",
      createtext1hb: "Create your Harris & Bruno Connect account",
      
      createtext2: "to access all our applications.",
      codecontactsupport: "If the company’s information is not correct, ",

      codecontactsupport2: "please contact us by",
      clickhere: "clicking here.",

      valablejusquau: "Available until ",

      KMcreatetext1: "Create MGI Connect accounts and provide client access",
      KMcreatetext2: "to the trial MGI Approve version.",
      confirmmail:
        "Your customer will finalize their account via the confirmation email.",

      name: "Name",
      Prénom: "First name",
      mailadd: "Email",
      Password2: "Password",
      confirmPassword: "Confirm Password",
      Entreprise2: "Company",
      Country2: "Country",
      Machines: "Machine Type",
      selectoption: "Select machine(s)",
      alraidyaccount: "I already have an account",
      createaccount2: "Create an account",

      enabled: "apps enabled",
      Tools: "Tools",
      toolenabled: "tools enabled",
      ressourcesenabled: "ressources",

      darkmode: "Dark mode",
      whitemode: "White mode",

      desactivateuser: "Désactiver l'utilisateur",
      activateuser: "Activer l'utilisateur",

      //Equipments
      chooseequipment2: "CHOOSE AN EQUIPMENT",
      myequipments: "My equipments",
      newequipment: "New equipment",
      addequipment: "Add Equipment",

      equipmenttype: "Equipment type",
      equimentnumber: "Equipment number:",
      jetcode: "Jet code",
      controlcode: "Control code",
      serialnumber: "Serial number",

      tofinalizeactivation:
        "Your equipment does not appear in the list click on the",
      tofinalizeactivation2: "button to add a new equipment.",

      no_equipment_found:
        "No equipment corresponding to provided informations was found",
      already_linked_equipment:
        "This equipment is already linked to your organization",
      success_add_equipment:
        "This equipment has been added to your organization",
      not_linkable_equipment:
        "This equipment cannot be added to your organization",
      equipment_successfuly_connected: "Equipment successfuly connected",
      finish: "Finish",

      not_connected_equipment: "This equipment is not connected",

      infostext: "Go to the JVTI on your equipment, press the 'i' menu.",
      infostext2: "The serial number will appear on this interface.",

      addequipmenttext:
        "Add equipment to start taking advantage of MGI CONNECT services immediately.",

        addequipmenttexthb:
        "Add equipment to start taking advantage of Harris & Bruno Connect services immediately.",

      downloadmsds: "Download MSDS",
      download: "Download",
      precoutil: "recommendation for use",

      renouvelerabo: "Renew subscription", 
      userpack: "Pack User",    
    },
  },

  fr: {
    translation: {
      // CGU: "Conditions générales d'utilisation",
      CGU: "CGU",
      CGUlink:
        "https://connect.mgi-labs.com/cgu/MGI_Connect_Conditions_générales_utilisation.pdf",
        hbCGUlink:
        "https://connect.harris-bruno-connect.com/cgu/HB_Connect_Conditions_générales_utilisation.pdf",
      //Account
      Tabkm: "Liste des comptes créés",
      Logout: "Se Déconnecter",
      login_text: "Se connecter",
      user_has_been_successfuly: "L'utilisateur {{mail}} a bien été",
      validated: "validé",
      rejected: "rejeté",
      error: "Erreur",
      error_validating:
        "Une erreur s'est produite lors de la validation de cet utilisateur",
      at_least_password: "Le mot de passe doit contenir au moins 8 caratères",
      password_must_match: "Les mots de passes doivent correspondre",
      valid_country: "Vous devez choisir un pays valide",
      valid_field: "Vous devez remplir ce champ",
      need_verification: "Votre compte doit être vérifié",
      email_used: "Cet adresse mail est déjà utilisée",
      already_rejected_title: "Utilisateur déjà rejeté",
      already_validated_title: "Utilisateur déjà validé",
      already_rejected: "Le compte de cet utilisateur a déjà été rejeté",
      already_validated:
        "Le compte de l'utilisateur {{mail}} a déjà été validé",
      must_machine: "Vous devez selectionner au moins une machine",
      mandatory: "Champs obligatoires",

      Catalogue: "Catalogue virtuel",
      Predict: "Maintenance ALPHAJET KM BF",
      Predict2: "Maintenance AccurioShine",
      ALPHAJETKMBFprod: "Production ALPHAJET KM BF",
      AccurioShineprod: "Production AccurioShine",

      Nouveautés: "Nouveautés",
      Utilisateurs: "Utilisateurs",
      utilisateurssur: "utilisateurs sur",
      equipments: "Equipements",
      connected: "Connectés",
      status: "Statut",
      Projetscata: "Projets et Catalogues virtuels",
      //Navbar
      Tester: "Tester",
      Home: "Accueil",
      My_Profile: "Mon Profil",
      Personal_Information: "Mon Profil",
      Personal_Information1: "Informations",
      Personal_Information2: "Personnelles",
      assistanceutilisateurs: "Assistance utilisateurs",
      suport_title: "Vous rencontrez des difficultés ?",
      suport_commercial_title: "Contact commercial",
      Password: "Mot de passe",
      Methods_of_payment: "Moyens de paiements",
      // User_management: "Gestion des Utilisateurs",
      // User_management1: "Gestion des",
      User_management: "Organisation",
      User_management1: "Organisation",
      User_management2: "Utilisateurs",
      Members: "Membres",
      Membres: "Membres",
      My_machines: "Mes Machines",
      Applications: "Applications",

      Applicationstexte:
        'Rendez vous sur la page "Applications" pour retrouver toutes les applications et outils disponible.',
      Security: "Sécurité",
      KMadduser: "Inviter",

      //Home
      Hello: "Bonjour",
      Welcome: ", Bienvenue sur La plateforme MGI Connect !",
      Discover: "Découvrir Approve",
      Decouvrir: "Découvrir",
      Ouvrir: "Ouvrir",
      vistsite: "Visiter le site",
      Registerproduct: "Enregistrer un produit",
      Shopfoilproduct: "Acheter du FOIL",
      Testermaintenant: "Tester maintenant",
      Abonner: "S'abonner",
      Extensions: "Extensions",
      packhometext:
        "Les packs MGI Connect vous donnent accès aux trois applications en ligne pour tirer le meilleur profit",
      packhometext2: "de vos équipements MGI.",
      users: "Utilisateur",
      New: "Venez découvrir les nouveautés",
      Click: "d'un simple clic !",
      AddUsers: "Ajouter des utilisateurs",
      AccesProfile: "Mon Profil",
      Organisation: "Organisation",
      Profile: "Mon Profil",
      Organisation_content:
        "Gérer votre organisation et inviter des collaborateurs à rejoindre votre espace de travail.",
      Profile_content:
        "Changer vos informations personnelles à tout moment en cliquant simplement sur l'onglet Profil.",
      Subscriptions: "Abonnements",
      Starter_text: "Actif : Pack MGI Connect :",
      Starter_text_1: "- 1 utilisateur",
      Starter_text_2: "- 100 Projets",
      Starter_text_3: "- 1 Catalogue Virtuel",

      More_informations: "Plus d'informations",
      upgrade: "Passer à l'offre supérieure",
      lasttime: "/ 24 heures",
      used: "Déjà utilisé",
      Availibleproj: "Projets",
      Number: "Nombre de catalogues virtuels",
      campaigns: "Campagnes en cours",
      month: "/Mois",
      books_used: "Catalogues virtuels utilisés sur",
      campaigns_sent: "Campagnes envoyées sur",
      projects_stored: "Projets stockés sur",
      Storage_space: "Espace de stockage",
      Year: "/An",
      projects_used: "Projets utilisés sur",
      number_projects: "Nombre de projets",
      Compte_assignés: "Comptes assignés:",
      compte_sur: "comptes sur",

      Unique: "Unique",
      Unique_content:
        "MGI Approve est la première application Cloud collaborative destinée aux Professionnels de l’Impression, aux Marques et aux Agences.",
      Strong: "Puissant",
      Strong_content:
        "MGI Approve traite en quelques secondes vos fichiers PDF et les transforme en objets réalistes en 3 dimensions, que l’on peut manipuler à distance, d’un simple clic.",
      Intuitif: "Intuitif",
      Intuitif_content:
        "MGI Approve s’utilise au travers d’un navigateur web, à partir de tout ordinateur, tablette ou smartphone. Son processus de validation intégrant discussion en ligne et annotations, reprend les standards des messageries grand public.",
      Sécurisé: "Sécurisé",
      Sécurisé_content:
        "MGI Approve est un service Cloud totalement sécurisé dont les données sont hébergées sur la plateforme OutScale de Dassault Systèmes. L’ensemble des échanges sont cryptés de bout en bout, garantissant ainsi une totale intégrité et confidentialité.",
      Marketing_digital: "Marketing digital",
      Marketing_content:
        "MGI Approve permet à moindre coût et sans délais, de démultiplier le nombre de maquettes proposées à un ou plusieurs clients",

      Vernis: "Vernis sélectif à plat ou en relief",
      Dorure: "Dorure à chaud à plat ou en relief",
      Impression: "Impression quadrichromie et +",
      Traits: "Traits de découpes et plis",

      Données_Protégées: "Données\nProtégées",
      Données_Confidentielles: "Données\nConfidentielles",
      Données_Cryptées: "Données\nCryptées",

      Cibler: "Cibler la valeur ajoutée",
      Mesurer: "Mesurer son impact",
      Créer: "Créer des campagnes marketing",

      //My Profile
      account_inf: "Information de compte",
      First_name: "Prénom",
      Email: "Adresse Mail",
      Company_adresse: "Entreprise / Adresse",
      Company: "Entreprise",
      company_code: "Code entreprise",
      telephone: "Téléphone",
      telephonemobile: "Téléphone mobile",
      copied: "Code entreprise copié",
      Country: "Pays",
      Address: "Adresse",
      zipcode: "Code Postal",
      CP: "Code Postal",
      phone: "Téléphone",
      mobile: "Téléphone Mobile",
      Position: "Poste occupé",
      City: "Ville",
      Photo: "Faites glisser une photo de profil ici",
      Choose: "Choisir un fichier",
      Secondary: "Secondaire",
      error_save_theme: "Erreur durant la sauvegarde du nouveau theme",
      createAccount: {
        success: "Votre compte MGI Connect a été créé avec succès !",
        successhb: "Votre compte Harris & Bruno Connect a été créé avec succès !",
        success_text:
          "Pour finaliser votre inscription, veuillez cliquer sur le lien qui a été envoyé à l'e-mail ",
        verification:
          "Votre compte MGI Connect sera validé par nos équipes sous 24h.",
          verificationhb:
          "Votre compte Harris & Bruno Connect sera validé par nos équipes sous 24h.",
        verification_text:
          "Vous recevrez un email une fois votre compte validé",
        verification_text2: "pour finaliser votre inscription.",
      },
      succesfuly_updated_infos:
        "Informations du profil correctement mises à jour",
      error_updating: "Erreur pendant la mise à jour des informations",

      KMcreateAccount: {
        success:
          "Créer des compte MGI Connect et délivrer un accès client à la version trial MGI Approve.",
        success_text:
          "Votre client finalisera son compte via l’e-mail de confirmation.",
        create_another_account: "Créer un autre compte",
      },
      KMAccountsTable: {
        company: "Compagnie",
        name: "Nom",
        email: "Email",
        createdAt: "Création",
        lastLogin: "Dernière connexion",
        never: "Jamais",
        lastCatalog: "Dernier catalogue",
        my_clients: "Mes Clients",
        no_last_catalog: "Pas de dernier catalogue virtuel",
        no_last_project: "Pas de dernier projet",
      },

      changeinfos: "Changez vos informations de profil",
      saveprofile: "Sauvegarder",

      need_help: "Besoin d'aide ?",

      //User Managment
      email: "Mail",
      first_name: "Prénom",
      last_name: "Nom",
      language: "Langue",
      redirect_app: "Redirection vers l'application",
      send_invite: "Envoyer l'invitation",
      your_first_name: "Votre prénom",
      your_last_name: "Votre nom",
      add_user: "Ajouter un utilisateur",
      status_updated: "Le statut a correctement été mis à jour",
      apps_updated:
        "Les applications de l'utilisateur ont correctement été mises à jour",
      error_save_apps:
        "Erreur durant la modifications des applications de l'utilisateur",
      confirm_delete: "Voulez-vous vraiment supprimer l'utilisateur {{user}} ?",
      Add_user: "Ajouter un utilisateur",
      Add_multiple_user: "Ajouter plusieurs utilisateurs",
      importerliste: "Importer une liste de contact",
      filecsv: "Fichier CSV, XLS, CSV Outlook",
      Abonnementactif: "Abonnement actif",
      jusquau: "Jusqu'au",
      nbuser: "Nombre d'utilisateurs",
      nbprojects: "Nombre de projets",
      nbbook: "Nombre de catalogues virtuels",
      user: "utilisateurs",
      projects: "projets",
      book: "catalogues virtuels",
      cataloguesvirtuels: "catalogue(s) virtuel(s)",
      Etendre: "Etendre",
      // Keep translations for later
      // Pack: {
      //   starter: "Pack Starter",
      //   pro: "Pack Pro",
      //   mgi: "Pack MGI",
      //   no: "No Pack",
      //   beta: "Pack Beta",
      //   ext_ut: "Extension 1 Utilisateur",
      //   ext_cat: "Extension 1 Catalogue virtuel",
      //   ext_pro: "Extension 500 Projets",
      // },
      Pack: {
        starter: "Starter Pack",
        pro: "Pro Pack",
        mgi: "MGI Pack",
        no: "No Pack",
        beta: "Beta Pack",
        ext_ut: "1 User Extension",
        ext_cat: "1 Virtual book Extension",
        ext_pro: "500 Projects Extension",
      },
      periode: "Période",
      debut: "Début",
      details: "Détails",
      Changement: "Changement",
      End: "Fin",
      ajoutuser: "Ajout d'utilisateur(s)",
      ajoutprojects: "Ajout de projet(s)",
      ajoutbook: "Ajout de catalogue(s) virtuel(s)",
      Commander: "Télécharger",
      Commandertext:
        "Une fois la demande d'extension de licence téléchargée, veuillez l'envoyer à votre distributeur.",
      orMore: " ou plus",
      ajoutPro: "Pack Pro",
      HT: "HT",

      Offrealademande: "Offre à la demande",
      Offrealademandetext:
        "Une offre dédiée à une demande spécifique, tel que l'intégration de MGI Approve dans votre flux de travail existant.",
      cbdecomptebesoin: "De combien de compte utilisateurs avez vous besoin ?",
      cbdeprojetbesoin: "De combien de projets avez vous besoin ?",
      cbdecatabesoin: "De combien de catalogues virtuels avez vous besoin ?",
      newabo: "Demande d'extension de licence",
      //Password
      Current_password: "Mot de passe actuel",
      New_password: "Nouveau mot de passe",
      Password_confirmation: "Confirmation mot de passe",
      Forgot: "Changer votre mot de passe",
      Send_email: "Réinitialiser votre mot de passe",
      Send_SMS: "Validation du code par téléphone",
      Sent_email: "Un mail a été envoyé à l'adresse : ",
      Confirm: "Confirmer",
      Deleted_user: "a été supprimé",
      changed_pwd: "Le mot de passe a bien été changé",
      deleteuser: "Supprimer l'utilisateur",
      changeuser: "Modifier l'utilisateur",
      delete: "Supprimer",
      //Change status

      close: "Fermer",
      user_status: "Statut utilisateur",
      standard: "Standard",
      standard_user_description:
        "Un utilisateur disposant du statut standard doit recevoir une autorisation d’un utilisateur Admin pour accéder au différentes applications, et fonctions de son organisation.",
      admin: "Administrateur",
      admin_user_description:
        "Un utilisateur disposant d’un statut Admin peut accéder à l’ensemble des application et fonctions de son organisation. Il dispose également des droit (de gestion) suivants :",
      admin_user_desc1: "Suppression / Ajout d’utilisateurs",
      admin_user_desc2: "Assigner un statut",
      apply: "Appliquer",

      //support

      Support: "Assistance",
      indentifiervous: "Identifiez vous",
      problemerencontre: "Problème rencontré sur l'Application",
      decrivezprob:
        "Décrivez les problèmes / difficultés que vous avez rencontrées :",
      decrivezprob_commercial: "Votre message",
      Soumettre: "Soumettre",
      lastName: "Nom",
      Entreprise: "Entreprise",
      Comptes_utilisateurs: "Comptes utilisateurs",
      support_success: "Votre message à bien été envoyé",
      support_error: "Une erreur s'est produite lors de l'envoi du message",

      topproject: "Top 3 des projets visités",
      visitespar: "visite(s)",

      // createaccount
      contactsupport: "Assistance utilisateurs",
      contactus: "Contactez nous",

      createtext1: "Créer votre compte MGI Connect pour accéder",
      createtext1hb: "Créer votre compte Harris & Bruno Connect pour accéder",
      createtext2: "à l’ensemble de nos applications.",
      codecontactsupport:
        "Si les informations de l'entreprise ne sont pas correctes,",
      codecontactsupport2: "veuillez nous contacter en",
      clickhere: "cliquant ici.",

      KMcreatetext1: "Créer des compte MGI Connect et délivrer un accès client",
      KMcreatetext2: "à la version trial MGI Approve.",
      confirmmail:
        "Votre client finalisera son compte via l’e-mail de confirmation.",

      valablejusquau: "Valable jusqu’au ",

      name: "Nom",
      Prénom: "Prénom",
      mailadd: "Adresse mail",
      Password2: "Mot de passe",
      confirmPassword: "Confirmation mot passe",
      Entreprise2: "Entreprise",
      Country2: "Pays",
      selectoption: "Sélection machine(s)",
      Machines: "Type de machine",
      alraidyaccount: "J'ai déja un compte",
      createaccount2: "Créer un compte",

      enabled: "apps actives",
      Tools: "Outils",
      toolenabled: "outils actifs",
      ressourcesenabled: "ressources",

      darkmode: "Mode noir",
      whitemode: "Mode blanc",
      desactivateuser: "Desactivate user",
      activateuser: "Activate user",

      //Equipments
      chooseequipment2: "CHOISIR UN ÉQUIPEMENT",
      myequipments: "Mes équipements",
      newequipment: "Nouvel équipement",
      addequipment: "Ajouter un Equipment",

      equipmenttype: "Type d'équipement",
      equimentnumber: "Numéro de l'équipement",
      jetcode: "Code jet",
      controlcode: "Code de contrôle",
      serialnumber: "Numéro de série",

      tofinalizeactivation:
        "Votre équipement ne figure pas dans la liste cliquer sur le bouton",
      tofinalizeactivation2: "pour ajouter un nouvel équipement.",

      no_equipment_found:
        "Aucun équipement correspondant aux informations renseignées n'a été trouvé",
      already_linked_equipment:
        "Cet équipement est déjà associé à votre groupe",
      success_add_equipment: "Cet équipement a été ajouté à votre groupe",
      not_linkable_equipment:
        "Cet équipement ne peut pas être ajouté à votre groupe",
      equipment_successfuly_connected: "Cet équipement a bien été connecté",
      not_connected_equipment: "Cet équipement n'est pas connecté",
      finish: "Terminer",

      infostext:
        "Accédez au JVTI sur votre équipement, appuyez sur le menu 'i'.",
      infostext2: "Le numéro de série apparaîtra sur cette interface.",

      addequipmenttext:
        "Ajouter un équipement pour bénéficier dès maintenant des services MGI CONNECT.",
        addequipmenttexthb:
        "Ajouter un équipement pour bénéficier dès maintenant des services Harris & Bruno Connect.",
        
      downloadmsds: "Télécharger MSDS",
      download: "Télécharger",
      precoutil: "préconisation d'utilisation",

      renouvelerabo: "Renouveler l'abonnement",
      userpack: "Utilisateur Pack",
    },
  },

  ja: {
    translation: {},
  },
};
