import { Auth, ThemeType } from "@mgi-labs/mgi-id";
import {
  GetProductDto,
  OrderCustomerDto,
  OrderRenewDto,
} from "../components/Domain/prestashop";
import { AppAuthorizationsType } from "../components/WorkteamUser";
import { KMUserDto } from "../hooks/UseKmUsers";

import {
  AccountStats,
  GroupRepresentation,
  UserRepresentation,
} from "../types";
import { SubscriptionLimitation } from "./limitation";
import { Machine } from "../components/Domain/machine.interface";

export default class Backend {
  constructor(
    public readonly rootUrl: string,
    public readonly auth: Auth,
    private readonly fetch: typeof window.fetch = (...args) =>
      window.fetch(...args)
  ) {}
  async fetchWithAuth(
    input: RequestInfo,
    init: RequestInit = {}
  ): Promise<Response> {
    init.headers = new Headers(init.headers);
    init.headers.set("Authorization", `Bearer ${this.auth?.token}`);

    return await this.fetch(input, init);
  }

  async resetPassword() {
    const url = this.expandURL(`/user/reset-password`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async getUserMail(uuid: string): Promise<any> {
    const url = this.expandURL("/user/" + uuid);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async getLimitation(): Promise<SubscriptionLimitation> {
    const url = this.expandURL(`/subscription`);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async changePassword(id: string, password: string) {
    const url = this.expandURL(`/user/${id}/change-password`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password }),
    });
    const dto = await response.json();
    return dto;
  }

  async createUser(
    email: string,
    lastName: string,
    firstName: string,
    language: string,
    app?: string
  ): Promise<{
    id: string;
  }> {
    const url = this.expandURL(`/user/create-user`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        lastName,
        firstName,
        language,
        app,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async getInfoGroup(
    groupId: string,
    realm: string
  ): Promise<{
    company: string;
    address: string;
    postCode: string;
    phone: string;
    country: string;
    mobilePhone: string;
  }> {
    const url = this.expandURL(
      `/user/get-info-group/${groupId}?realm=${realm}`
    );
    const response = await this.fetch(url.toString());
    const test = await response.json();
    return test;
  }

  async changeUserAccess(
    userId: string,
    realm: string,
    hasAccess: boolean
  ): Promise<{
    company: string;
    address: string;
    postCode: string;
    phone: string;
    country: string;
    mobilePhone: string;
  }> {
    const url = this.expandURL(`/user/change-user-access`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        realm,
        hasAccess,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async createNewUser(
    email: string,
    lastName: string,
    firstName: string,
    password: string,
    company: string,
    country: string,
    address: string,
    zipcode: string,
    phone?: string,
    mobile?: string,
    redirectUri?: string | null,
    app?: string,
    lang?: string,
    groupId?: string,
    registerEquipment?: string | null,
    realm?: string,
    sn?: string | null,
    key?: string | null
  ): Promise<
    | {
        id: string;
      }
    | string
  > {
    const url = this.expandURL(`/user/create-new-user`);
    const response = await this.fetch(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        lastName,
        firstName,
        password,
        company,
        country,
        app,
        lang,
        groupId,
        redirectUri,
        address,
        zipcode,
        phone,
        mobile,
        registerEquipment,
        realm,
        sn,
        key,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async createNewKMUser(
    email: string,
    lastName: string,
    firstName: string,
    company: string,
    country: string,
    app?: string,
    lang?: string,
    groupId?: string
  ): Promise<
    | {
        id: string;
      }
    | string
  > {
    const url = this.expandURL(`/km-user/create-new-km-user`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        lastName,
        firstName,
        company,
        country,
        app,
        lang,
        groupId,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async validateNewUser(
    uuid: string,
    validate: boolean,
    redirectUrl?: string
  ): Promise<boolean> {
    const url = this.expandURL(`/user/validate-new-user`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uuid,
        validate,
        redirectUrl,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async updateUserInfos(infos: {
    firstname: string | undefined;
    lastname: string | undefined;
    company: string | undefined;
    country: string | undefined;
    phone: string | undefined;
    mobile: string | undefined;
    address: string | undefined;
    zipcode: string | undefined;
  }) {
    const url = this.expandURL(`/user/update-user-infos`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(infos),
    });
    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async updateUserStatus(isAdmin: boolean, userId: string) {
    const url = this.expandURL(`/user/${userId}/update-user-status`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ isAdmin }),
    });
    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async updateUserTheme(theme: ThemeType, userId: string, realm: string) {
    const url = this.expandURL(`/user/${userId}/update-user-theme`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status: { theme }, realm }),
    });
    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async updateUserApps(apps: AppAuthorizationsType[], userId: string) {
    const url = this.expandURL(`/user/${userId}/update-user-apps`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apps),
    });
    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async getKMStats(uuids: string[]): Promise<AccountStats[]> {
    const url = new URL(
      `${
        process.env.REACT_APP_APPROVE_BACKEND_URL
      }/stats/globalStats?uuids=${uuids.join(",")}`
    );
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async getApproveStats(): Promise<Omit<AccountStats, "uuid">> {
    const url = new URL(
      `${process.env.REACT_APP_APPROVE_BACKEND_URL}/stats/globalStatsForTeam`
    );
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async linkMachineV1ToTeam(
    jetCode: string,
    controlCode: string,
    type: string,
    number: number,
    equipmentName: string
  ): Promise<{ machine: Machine; isConnected: boolean }> {
    // const user = await this.auth.loadUserProfile();

    const url = new URL(
      `${process.env.REACT_APP_MACHINE_BACKEND_URL}/machines/add-new-equipment`
    );
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        controlCode: controlCode,
        jetCode: jetCode,
        number: number,
        equipmentName: equipmentName,
        customer: "",
      }),
    });
    if (!response.ok) {
      if (response.status === 405) throw new Error("not_linkable_equipment");
      if (response.status === 406) throw new Error("no_equipment_found");
      if (response.status === 409) throw new Error("already_linked_equipment");
    }
    return (await response.json()) as any;
  }

  async getAllKmUsers(): Promise<KMUserDto[]> {
    const url = this.expandURL(`/km-user/get-all-km-users`);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async getAllProducts(): Promise<{ products: GetProductDto[] }> {
    const url = this.expandURL(`/prestashop/products`);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async createOrder(order: OrderCustomerDto) {
    const url = this.expandURL(`/prestaUser/create-order/`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    if (response.ok) {
      return response.blob();
    } else {
      throw response;
    }
  }

  async createRenewOrder(order: OrderRenewDto) {
    const url = this.expandURL(`/prestaUser/create-renew/`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    if (response.ok) {
      return response.blob();
    } else {
      throw response;
    }
  }

  async sendCommand(order: OrderCustomerDto): Promise<{ url: string }> {
    const url = this.expandURL(`/prestashop/command/`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    if (response.ok) {
      return response.json();
    } else {
      throw response;
    }
  }

  async disableUser(id: string): Promise<void> {
    const url = this.expandURL(`/km-user/${id}/disable-user`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "post",
    });
    if (response.ok) {
    } else {
      throw response;
    }
  }

  async sendSupport(
    firstName: string,
    lastName: string,
    mail: string,
    project: string,
    content: string,
    realm: string
  ): Promise<any> {
    const url = this.expandURL(`/support/create`);
    const response = await this.fetch(url.toString(), {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName,
        lastName,
        mail,
        project,
        content,
        realm,
      }),
    });
    if (response.ok) {
      const dto = await response.json();
      return dto;
    } else {
      throw response;
    }
  }

  async deleteUser(ids: string[]) {
    const url = this.expandURL(`/user/delete-user`);
    const response = await this.fetchWithAuth(url.toString(), {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids }),
    });

    if (response.ok) {
      return;
    } else {
      throw response;
    }
  }

  async getGroupUsers(): Promise<UserRepresentation[]> {
    const url = this.expandURL(`/group`);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      const dto = (await response.json()) as UserRepresentation[];
      return dto;
    } else {
      throw response;
    }
  }

  async getCurrentGroup(): Promise<GroupRepresentation> {
    const url = this.expandURL(`/group/currentGroup`);
    const response = await this.fetchWithAuth(url.toString());
    if (response.ok) {
      const dto = (await response.json()) as GroupRepresentation;
      return dto;
    } else {
      throw response;
    }
  }

  private expandURL(url: string): string {
    return this.rootUrl ? new URL(url, this.rootUrl).toString() : url;
  }
}
